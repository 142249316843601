import * as React from "react";

function SvgLotusPoseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="1em"
      height="1em"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="currentcolor"
        d="M50,1.239c6.746,0,12.743,5.246,12.743,11.992c0,7.496-5.997,12.742-12.743,12.742  s-11.992-5.246-11.992-12.742C38.008,6.485,43.254,1.239,50,1.239L50,1.239z"
      ></path>
      <path
        fill="currentcolor"
        d="M38.756,28.971H50l0,0l0,0h11.993c2.999,0,4.497,0,7.495,4.498  c2.998,3.749,6.747,12.742,8.245,19.488c1.499,6.746,2.249,8.994-3.747,12.742S58.245,71.696,50,71.696l0,0  c-8.245,0-17.988-2.249-23.984-5.997s-4.498-5.996-2.998-12.742c1.499-6.747,5.247-15.74,8.245-19.488  C33.51,28.971,35.009,28.971,38.756,28.971L38.756,28.971z M50.75,61.202h9.744c0,2.998-4.497,5.247-9.744,5.247  c-5.997,0-10.494-2.249-10.494-5.247H50.75z"
      ></path>
      <path fill="currentcolor" d="M50,76.756L50,76.756"></path>
      <path
        fill="currentcolor"
        d="M50,76.756c-9.744,0.75-14.99-5.06-19.488-5.81c-5.246-0.75-5.996,0-10.493,2.998  c-4.498,2.998-13.492,10.494-15.74,15.74c-2.999,5.247-0.75,9.744,8.244,8.995c8.994-1.5,20.237-3.748,28.481-5.997  c3.748-0.749,3.748-1.499,8.995-1.499c5.248,0,5.248,0.75,8.996,1.499c8.244,2.249,19.486,4.497,28.481,5.997  c8.994,0.749,11.243-3.748,8.244-8.995c-2.249-5.246-11.242-12.742-15.739-15.74s-5.247-3.748-10.494-2.998  C64.991,71.696,59.743,77.506,50,76.756"
      ></path>
    </svg>
  );
}

export default SvgLotusPoseIcon;
