import * as React from "react";

function SvgBehaleIcon(props: React.SVGProps<SVGSVGElement>) {
  let primaryFill = "text-primary-light";
  let secondaryFill = "text-primary-dark";

  if (props.fill && props.fill == "white") {
    primaryFill = "text-white";
    secondaryFill = "text-primary-light";
  } 

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="1em"
      height="1em"
      viewBox="0 0 369 369"
      xmlSpace="default"
      {...props}
    >
      <defs>
        <clipPath id="clip1">
          <path d="M 22.28125 18.617188 L 368.003906 18.617188 L 368.003906 364.339844 L 22.28125 364.339844 Z M 22.28125 18.617188 " />
        </clipPath>
        <clipPath id="clip2">
          <path d="M 14.074219 10.570312 L 359.796875 10.570312 L 359.796875 356.296875 L 14.074219 356.296875 Z M 14.074219 10.570312 " />
        </clipPath>
      </defs>
      <g id="surface1">
        <g clipPath="url(#clip1)" clipRule="nonzero">
          <path
            className={`fill-current ${secondaryFill}`}
            stroke="none"
            d="M 367.914062 185.839844 C 367.90625 185.695312 367.890625 185.554688 367.878906 185.410156 C 366.34375 140.929688 348.058594 99.347656 316.191406 68.082031 C 283.683594 36.183594 240.695312 18.617188 195.140625 18.617188 C 148.96875 18.617188 105.558594 36.597656 72.910156 69.246094 C 40.261719 101.894531 22.28125 145.304688 22.28125 191.476562 C 22.28125 215.007812 41.425781 234.152344 64.953125 234.152344 C 88.488281 234.152344 107.628906 215.007812 107.628906 191.476562 C 107.628906 125.277344 161.488281 71.417969 227.6875 71.417969 C 293.324219 71.417969 346.042969 122.792969 347.707031 188.375 C 347.707031 188.40625 347.710938 188.4375 347.714844 188.46875 C 347.734375 189.472656 347.746094 190.476562 347.746094 191.476562 C 347.746094 275.625 279.289062 344.082031 195.140625 344.082031 C 189.546875 344.082031 185.011719 348.617188 185.011719 354.210938 C 185.011719 359.804688 189.546875 364.339844 195.140625 364.339844 C 241.3125 364.339844 284.722656 346.359375 317.375 313.710938 C 350.023438 281.0625 368.003906 237.652344 368.003906 191.476562 C 368.003906 189.601562 367.972656 187.703125 367.914062 185.839844 Z M 367.914062 185.839844 "
          />
        </g>
        <g clipPath="url(#clip2)" clipRule="nonzero">
          <path
            className={`fill-current ${primaryFill}`}
            stroke="none"
            d="M 359.707031 177.792969 C 359.703125 177.652344 359.683594 177.511719 359.675781 177.367188 C 358.140625 132.886719 339.851562 91.304688 307.988281 60.035156 C 275.476562 28.140625 232.488281 10.570312 186.9375 10.570312 C 140.765625 10.570312 97.355469 28.554688 64.703125 61.203125 C 32.054688 93.851562 14.074219 137.261719 14.074219 183.433594 C 14.074219 206.964844 33.21875 226.109375 56.75 226.109375 C 80.28125 226.109375 99.425781 206.964844 99.425781 183.433594 C 99.425781 117.234375 153.28125 63.375 219.484375 63.375 C 285.121094 63.375 337.839844 114.75 339.5 180.328125 C 339.5 180.359375 339.507812 180.394531 339.507812 180.425781 C 339.527344 181.425781 339.542969 182.433594 339.542969 183.433594 C 339.542969 267.582031 271.082031 336.039062 186.9375 336.039062 C 181.34375 336.039062 176.808594 340.574219 176.808594 346.167969 C 176.808594 351.761719 181.34375 356.296875 186.9375 356.296875 C 233.109375 356.296875 276.519531 338.316406 309.167969 305.664062 C 341.816406 273.015625 359.796875 229.605469 359.796875 183.433594 C 359.796875 181.554688 359.769531 179.660156 359.707031 177.792969 Z M 359.707031 177.792969 "
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgBehaleIcon;
